const reviews = [
  {
    id: 1,
    image: '/images/reviews-gallery/review-concealed.jpg',
    quote: "Ashley has done a number of jobs in my property including security lights, garden lighting and installation of down lights as well as some really impressive concealed LED strip lighting. I'd highly recommend Ashley, he clearly takes pride in his work.",
    name: "Tom M (Arkley)"
  },
  {
    id: 2,
    image: '/images/reviews-gallery/review-garage.jpg',
    quote: "Over the last year, Ashley from AG Electrical Servies has carried out all the electrical work in my garage from installation of the consumer unit to outdoor lighting. I found him to be very reliable and conscientious, would highly recommend him.",
    name: "Jonathan Mears (East Barnet)"
  },
  {
    id: 3,
    image: '/images/reviews-gallery/review-kitchen.jpg',
    quote: "Ashley did a fantastic and professional job on our kitchen. Ashley is extremely reliable and trustworthy, we highly recommend him for all your electrical needs!",
    name: "Charlotte Wolff (Bow)"
  },
  {
    id: 4,
    image: '/images/reviews-gallery/review-decking.jpg',
    quote: "Ashley was always courteous, friendly and very creative with his ideas and showed a clear understanding of what I was trying to achieve. He did a superb job for me including designing of the layout of my new decking lights and his attention to detail was second to none. I am thrilled with the outcome and would highly recommend him.",
    name: "Jos Day (Whetstone)"
  },
  {
    id: 5,
    image: '/images/reviews-gallery/review-consumer-unit.jpg',
    quote: "Ashley updated our fuse board which was over 30 years old. He was conscientious, friendly and transparent throughout the 2 days he worked here. He also regularly cleaned up after himself and always ensured that he left the house in a clean state",
    name: "Luke Perera (Enfield)"
  },
];

export default reviews;